import Instance from "./instance";

export const GetDashMetrics = async (from, to) => {
  try {
    return await Instance.instanceToken.get(`/metrics/dashboard`, {
      params: { from, to },
    });
  } catch (error) {
    return error;
  }
};
