<template>
  <HomeLayout>
    <b-container
      class="
        d-flex
        flex-column
        align-items-start
        justify-content-start
        px-0
        position-relative
      "
    >
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          donor-card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            donor-card-container
          "
        >
          <b-row class="px-3 py-0 body-container-cards" no-gutters>
            <b-col class="px-2 px-lg-2 py-3 col-config">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                "
              >
                Donors
              </h6>
              <b-row class="px-4 py-0">
                <h4
                  class="
                    text-left
                    count-text
                    font-secondary font-weight-bold
                    text-dark
                    mr-1
                    mt-1
                  "
                >
                  {{ totalDonors }}
                </h4>
                <span
                  class="
                    text-left
                    font-secondary font-weight-normal
                    text-prime-gray
                    ml-1
                    mt-5
                  "
                  >total donors</span
                >
              </b-row>
            </b-col>

            <!-- <div class="column-divider-vertical" /> -->
            <b-col class="px-0 px-md-2 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                  ml-2
                "
              >
                Videos
              </h6>
              <b-row class="px-5 py-0">
                <h4
                  class="
                    text-center
                    count-text
                    font-secondary font-weight-bold
                    text-dark
                    mt-1
                  "
                >
                  {{ totalVideos }}
                </h4>
                <span
                  class="
                    text-left
                    font-secondary font-weight-normal
                    text-prime-gray
                    ml-1
                    mt-5
                    w-50
                  "
                  >total videos created</span
                >
              </b-row>
            </b-col>
            <!-- <div class="column-divider-vertical" /> -->
            <b-col class="px-0 px-md-2 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                  px-2
                "
              >
                Total Amount of Donations
              </h6>
              <b-row class="px-2 body-container" no-gutters>
                <!-- last month's numbers -->
                <b-col cols="12" class="mb-2 mb-md-0">
                  <div v-if="!this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">Until</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{ monthName + " " + yearName }}
                    </h6>
                  </div>
                  <div v-if="this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">From</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.fromDate).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                    <h6 class="font-primary text-prime-gray">To</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.toDate).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                  </div>

                  <!-- main value -->
                  <h1 class="text-primary font-primary text-x-large">
                    {{ getFormattedNumbers(allTimeTotal) }}
                  </h1>
                </b-col>
                <!-- this month's numbers -->
              </b-row>
            </b-col>
            <!-- <div class="column-divider-vertical" /> -->
            <b-col class="px-0 px-md-2 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  px-2
                  mt-2
                "
              >
                Average Donation Value
              </h6>
              <b-row class="px-0 body-container px-2" no-gutters>
                <!-- last month's numbers -->
                <b-col cols="12" md="6" class="mb-2 mb-md-0">
                  <div v-if="!this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">Until</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{ monthName + " " + yearName }}
                    </h6>
                  </div>
                  <div v-else>
                    <h6 class="font-primary text-prime-gray">From</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.fromDate).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                    <h6 class="font-primary text-prime-gray">To</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.toDate).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                  </div>
                  <!-- main value -->
                  <h1 class="text-secondary font-primary text-x-large">
                    {{ getFormattedNumbers(averageDonation) }}
                  </h1>
                </b-col>
                <!-- this month's numbers -->
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- target video row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          video-card-row
        "
        no-gutters
      >
        <b-card
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            video-card-container
          "
        >
          <b-row class="px-0 py-0 body-container-dashboard">
            <b-col class="px-0 left-column">
              <b-col>
                <h6
                  class="
                    text-left text-dark text-large
                    font-weight-semi-bold font-primary
                    mb-4
                    mt-2
                  "
                >
                  Top 5 Target Donors/Videos
                </h6>
                <b-table
                  :bordered="false"
                  :borderless="true"
                  :hover="true"
                  :striped="true"
                  selectable
                  select-mode="single"
                  small
                  class="table-element"
                  thead-class="table-header font-primary"
                  tbody-class="table-body"
                  :fields="HeaderFields"
                  :items="targetVideoList"
                  show-empty
                  :busy="isLoading"
                >
                  <!-- index no column -->
                  <template v-slot:cell(indexNo)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <span class="hits-count-text text-dark">{{
                        data.index + 1
                      }}</span>
                    </b-col>
                  </template>
                  <!-- donor column -->
                  <template v-slot:cell(donar)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <span class="hits-count-text text-dark">{{
                        data.value.name
                      }}</span>
                    </b-col>
                  </template>
                  <!-- video link column -->
                  <template v-slot:cell(targetVideo)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-center
                          text-dark
                          main-text-container
                        "
                      >
                        <b-img
                          :src="data.value.previewImage"
                          style="width: 35px; height: 20px; object-fit: cover"
                          rounded="sm"
                          @error="
                            data.value.previewImage = getPlaceholderImageURL
                          "
                        />
                        <span class="ml-3 text-truncate">
                          {{ data.value.title }}
                        </span>
                      </div>
                    </b-col>
                  </template>
                  <!-- hits column -->
                  <template v-slot:cell(hitsCount)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <span class="hits-count-text text-dark">{{
                        data.value.hits ? data.value.hits : 0
                      }}</span>
                    </b-col>
                  </template>
                </b-table>
              </b-col>
            </b-col>
            <div class="column-divider-line" />
            <b-col class="px-0 px-md-4 py-2 mt-lg-0 mb-5">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  pl-3
                  ml-2 ml-lg-0
                "
              >
                Filter
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mb-4
                  ml-2
                "
              >
                <b-col cols="12">
                  <b-form-datepicker
                    class="
                      date-picker
                      font-primary
                      datepicker
                      align-items-center
                    "
                    id="fromDate"
                    v-model="fromDate"
                    no-flip
                    :min="min"
                    :max="new Date().toISOString().substr(0, 10)"
                    placeholder="From"
                    locale="en-US"
                    button-variant="primary"
                    nav-button-variant="primary"
                    today-variant="primary"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                    :default-value="new Date()"
                    :disabled-date="disabledAfterToday"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  mb-4
                  w-100
                  ml-2
                "
              >
                <b-col cols="12">
                  <b-form-datepicker
                    class="
                      date-picker
                      font-primary
                      datepicker
                      align-items-center
                    "
                    id="toDate"
                    v-model="toDate"
                    no-flip
                    :min="min"
                    :max="new Date().toISOString().substr(0, 10)"
                    placeholder="To"
                    locale="en-US"
                    button-variant="primary"
                    nav-button-variant="primary"
                    today-variant="primary"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                    :default-value="new Date()"
                    :disabled-date="disabledAfterToday"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row>
                <div v-if="this.displayError" class="errorMsg">
                  Please Select Valid Date Range
                </div>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  row-padding
                  w-100
                  ml-2
                "
                no-gutters
              >
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    px-0
                    py-2
                    setUpBtn
                  "
                  cols="6"
                >
                  <b-button
                    variant="primary"
                    class="btn-submit text-white mt-2"
                    @click="filterMetrics"
                    >Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
// layout
import HomeLayout from "@/layout/HomeLayout";
import //GetUserAnalytics,
"@/services/analytics.service";

import { GetDashMetrics } from "@/services/dashboard.service";
import { formatPrice } from "@/util/formatPrice.js";

export default {
  name: "Dashboard",
  components: {
    HomeLayout,
  },
  data() {
    return {
      isLoading: false,
      dateRangeApplied: false,
      totalDonors: null,
      totalVideos: null,
      allTimeTotal: null,
      averageDonation: null,
      monthName: null,
      yearName: null,
      fromDate: new Date(),
      toDate: new Date(),
      displayError: false,
      targetVideoList: [],
      totalUsers: {
        count: 0,
      },
      HeaderFields: [
        {
          key: "indexNo",
          label: "#",
        },
        {
          key: "donar",
          label: "Donor",
        },
        {
          key: "targetVideo",
          label: "Video",
        },
        {
          key: "hitsCount",
          label: "Hits",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getDash();
    this.month();
    // this.getUserAnalytics();
  },
  methods: {
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    month() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date();
      this.monthName = month[d.getMonth()];
      this.yearName = d.getFullYear();
    },

    async getDash(from, to) {
      try {
        this.isLoading = true;

        let { data } = await GetDashMetrics(from, to);
        if (data.code == 200 || data.message == "success") {
          // total user count
          //this.totalUsers.count = data.content;
          this.totalDonors = data.content.totalDonors;
          this.totalVideos = data.content.totalVideos;
          this.allTimeTotal = data.content.allTimeTotal;
          this.averageDonation = data.content.averageDonation;
          this.fromDate = data.content.dateRange.from
            ? data.content.dateRange.from
            : null;
          this.toDate = data.content.dateRange.to
            ? data.content.dateRange.to
            : null;
          this.dateRangeApplied = data.content.dateRangeApplied;
          this.targetVideoList = data.content.topDonors.map((x) => {
            return {
              donar: {
                id: x._id,
                name: x.firstName + " " + x.lastName,
              },
              targetVideo: {
                id: x._id,
                title: x.targetVideoTitle,
                previewImage: x.targetVideoPreviewImage,
              },
              hitsCount: {
                id: x._id,
                hits: x.views,
              },
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Users",
            content:
              "Unable to retrieve Donor Details' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading = false;
      } catch (error) {
        console.log("ERROR DB01");
      } finally {
        this.isLoading = false;
      }
    },
    getFormattedNumbers(text) {
      if (!text) return "$0.00";
      return formatPrice(text);
    },
    filterMetrics() {
      if (this.fromDate > this.toDate) {
        this.displayError = true;
      } else {
        this.displayError = false;
      }
      var from = this.fromDate;
      var to = this.toDate;
      console.log("FILTER", from, to);
      this.getDash(from, to);
      this.dateRangeApplied = true;
    },
  },
};
</script>
<style scoped>
.col-config {
  width: auto;
}
.donor-card-row {
  height: auto;
  min-height: 180px;
}
.donor-card-row .donor-card-container {
  width: 100%;
  min-height: 180px;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  border: 1px solid rgba(135, 147, 163, 0.25);
}
.video-card-row {
  height: auto;
  min-height: 380px;
}
.video-card-row .video-card-container {
  width: 100%;
  min-height: 380px;
  background-color: var(--white);
  border-radius: 0 0 16px 16px;
  border: 1px solid rgba(135, 147, 163, 0.25);
}
.body-container {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.body-container-dashboard {
  display: grid;
  grid-template-columns: 68% 32%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.btn-submit {
  padding-left: 70px;
  padding-right: 70px;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.body-container-cards {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* grid-gap: 10px;
  padding: 10px; */
}
.body-container::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.body-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.body-container::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.body-container .column-divider {
  width: 355px;
  height: 100%;
  border-top: none;
  border-left: 1px solid var(--light);
}
.body-container .first-column-divider {
  width: 753px;
  height: 100%;
  border-top: none;
  border-left: 2px solid var(--light);
}
.column-divider-line {
  border-left: 2px solid var(--light);
  height: 100%;
  position: absolute;
  right: 355px;
  top: 0;
}
.vertical-line {
  border-left: 2px solid var(--light);
  height: 100%;
  top: 0;
}
.count-text {
  font-size: 64px;
}
.datepicker >>> .form-control {
  box-shadow: none;
  /* color: var(--prime-gray) !important; */
}
.date-picker >>> .btn .b-icon.bi {
  color: var(--prime-gray);
}
.row-padding {
  padding-bottom: 100px;
}
/* table element css */
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 0.9rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body td {
  padding: 10px;
}
.table-element >>> .table-body .main-text-container {
  max-width: 400px;
}
.table-element >>> .table-body .main-text-container span {
  display: inline-block;
}
.errorMsg {
  font-size: 13px;
  color: red;
}

@media (max-width: 767.98px) {
  .column-divider-line {
    border-left: none;
  }
  .body-container .first-column-divider {
    border-left: none;
  }
  .col-config {
    width: 100%;
  }
  .body-container-cards {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .vertical-line {
    border-left: none;
  }
}

@media (max-width: 991.98px) {
  .column-divider-line {
    border-left: none;
  }
  .body-container .first-column-divider {
    border-left: none;
  }
  .col-config {
    width: 100%;
  }
  .body-container-cards {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .vertical-line {
    border-left: none;
  }
}
</style>
